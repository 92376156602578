@import (reference) "mobile.less";

@media(min-width: 1280px) {

    body {
        padding-top: 0;

        @media(max-width: 1340px) {
            padding-left:60px;
        }

    }


    .wrapper {
        width: 1200px;
        padding: 0 20px;
    }

    h1 {
        margin-left: 120px;
    }

    header {
        background-image: url(../img/header_d.png);

        .callback {
            margin-right: 100px;
        }

        .phone {
            margin-right: 120px;
        }

        .logo {
            margin-bottom: 60px;
            margin-left: 120px;
        }

        .desc {
            margin-left: 120px;
        }

        .advantages {
            width: 770px;
            margin-left: 20px;
            margin-top: 105px;

            li {
                &.lab {
                    width: 250px;
                    margin-right: 27px;
                }

                &.upper {
                    width: 220px;
                    margin-right: 27px;
                }

                &.gost {
                    width: 215px;
                }
            }
        }

        .delivery {
            width: 380px;
            margin-top: 90px;
            margin-right: 20px;
            padding: 0 65px 12px 40px;

            &:before {
                top: -50px;
                width: 0;
                height: 0;
                border-right: 380px solid transparent;
                border-bottom: 50px solid #212121;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -50px;
                width: 0;
                height: 0;
                left: 0;
                border-left: 380px solid transparent;
                border-top: 50px solid #212121;
            }
        }

        .form_back {
            width: 380px;
            margin-top: 0;
            margin-right: 20px;

            &:after {
                width: 1180px;
                bottom: 69px;
            }

            form {                
                background-image: url(../img/header_form_d.png);
                padding: 55px 45px 66px;
            }
        }

        .map {
            width: 800px;
            margin-top: -49px;
            height: 351px;
            overflow: hidden;

            .ymap {
                height: 449px;
                top: -51px;
            }

            .address_desc {
                // transform: none;
            }
        }
    }

    section.price {
        &>.wrapper {
            width: 1200px;

             &:before {
                width: 964px;
                height: 693px;
                top: 120px;
                right: -155px;
            }

            &:after {
                width: 380px;
            }
        }

        h2 {
            width: 380px;
            margin-top: -145px;
            padding-top: 125px;
        }

        .title {
            width: 380px;
        }

        .content {
            width: 730px;
            top: 209px;
            right: 50px;
        }

        .table {

            .td {
                &:first-child {
                    padding-left: 45px;
                    width: 190px;
                }

                &:last-child {
                    padding-right: 45px;
                }
            }
        }
    }

    section.seconds60 {

        form {
            width: 640px;
            right: -70px;
            padding-right: 85px;
            top: 111px;
            height: 417px;

            &:before {
                top: -79px;
                border-right: 640px solid transparent;
                border-bottom: 79px solid rgba(0, 0, 0, 0.4);
            }
            &:after {
                bottom: -79px;
                border-left: 640px solid transparent;
                border-top: 79px solid rgba(0, 0, 0, 0.4);
            }

            label.name, label.brand {
                margin-right: 64px;
            }
        }
    }

    section.equipment {

        ul {
            width: 100%;

            li {
                display: inline-block;
                vertical-align: top;
                width: 545px;
                padding: 35px 230px 30px 35px;
                min-height: 280px;

                &:nth-child(2n-1) {
                    margin-right: 50px;
                }

                .img {
                    width: 220px;
                    height: 160px;
                    position: absolute;
                    top: 35px;
                    right: -20px;
                }

            }
        }
     }

      section.about {

          ul.pluses {
              li {
                  margin-right: 60px;
                  width: 340px;

                  &.experience {
                      padding-right: 35px;
                  }

                  &.staff {
                      padding-right: 65px;
                  }

                  &.clients {
                      .icon {
                        top: 10px !important;
                      }
                  }
              }
          }

         .nav {
             a {
                margin-right: 75px;
             }
         }

         .manufacture {
             ul.manufacture-slider {
                 width: 1200px;
                 margin-left: -20px;

                 li {
                     width: 399px;
                 }
             }
         }

         .certificates {

             ul.pluses {
                float: left;
                width: 340px;
                margin-right: 30px;

                li {
                    display: block;
                    width: 100%;
                    padding-left: 0;
                    padding-top: 55px;

                    &.clients:before {
                        top: 15px;
                    }
                }
             }

             ul.certificates-slider  {
                 li, li:first-child {
                     margin: 0 20px;
                     width: 229px;
                     height: 333px;
                     line-height: 333px;

                     &:last-child {
                         margin-right: 0;
                     }
                 }
             }
         }

         .park {
             ul.park-slider {
                 width: 1200px;
                 margin-left: -20px;

                 li {
                     width: 399px;
                 }
             }
         }

         .lab {
             .wrapper {
                 width: 1200px;
             }

             ul.pluses {
                 width: 760px;
                 li {
                     margin-right: 0;
                     width: 300px;
                     vertical-align: middle;

                     &.gost:before {
                         top: 4px;
                     }

                     &:first-child {
                         margin-right: 80px;
                     }
                 }
             }

             form {
                 right: 20px;
             }

             img {
                 width: 380px;
             }

             p {
                 width: 760px;
             }
         }
      }

      section.clients {
        
        .big_clients .caroufredsel_wrapper {
            padding: 0 1000px 0 360px;
        }     

        ul.clients-slider {

            li {
                cursor: default;

                .title {
                    margin-left: -160px;
                    position: relative;
                    z-index: 2;
                }

                ul {
                    margin-left: -100px;
                    width: 730px;
                    position: relative;
                    z-index: 2;
                }
            }
        }

        .logos {
            li {
                margin-right: 35px;
            }
        }
    }

    section.watch {
        background: url(../img/watch_d.png) top center no-repeat;
        padding-bottom: 200px;
        margin-top: 0;

        &:before {
            height: 6px;
            top: 116px;
        }

        &:after {
            bottom: 115px;
        } 

        .connect {
            margin-left: 60px;

            .pluses {
                .text {
                    width: 410px;
                }
            }

            .title {
                width: auto;
            }

            .content {
                width: 450px;
            }
        }

        form {
            width: 380px;
            padding: 0 0 0 80px;
            margin-top: 135px;

        }
    }

    section.team {

        .wrapper {
            padding-bottom: 180px;

            &:after {
                right: -25px;
            }
        }

        ul {
            li {
                width: 260px;
                margin-left: 40px;

                &:nth-child(2n) {
                    margin-left: 40px;
                }

                &:nth-child(4n-3) {
                    margin-left: 0; 
                }
            }
        }
    }

    footer {
        margin-top: 45px;

        .hr {
            width: 1040px;
        }

        .seo_id {
            margin-right: 60px;
        }

        .polite_a {
            margin-left: 60px;
        }
    }

    .burger {
        display: none;
    }

    .menu {
        height: 100%;
        width: 60px;

        .logo {
            text-align: center;
            margin-top: 0;
            img {               
                transform: scale(0.58) rotate(-90deg) translateY(-135px); 
                margin-left: 0;
            }
        }

        ul {
            li {
                a {
                    span {
                        opacity: 0;
                    }
                }
            }
        }

        &:hover {
            width: 320px;

            .logo {

                img {
                    transform: none;
                }    
            }

            ul {
                padding-left: 5px;

                li {
                    a {
                        span {
                            opacity: 1;
                        }
                    }
                }
            }

            .email, .phone, .button {
                opacity: 1;
            }
        }
    }
}


@media(min-width: 1921px) {
    header {
        background-image: url(../img/header_2560.png);

        &:after {
            margin-left: -621px;
            top: 601px;
            transform: matrix(1, 0.124, 0, 1, 0, 0) translateX(50%);
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 816px;
            height: 4px;
            width: 100%;
            background: #1d1d1d;
            transform: matrix(1, 0.124, 0, 1, 0, 0);
        }
    }

    section.seconds60 {
        background: url(../img/seconds60_2560.png) center center;
        height: 811px;
        padding-top: 38px;
        margin-top: -168px;
        margin-bottom: -89px;

        &:before {
            top: 154px;
            height: 5px;
        }

        &:after {
            bottom: 153px;
            height: 5px;
        }
    }

    section.clients {
        background: #1d1d1d;
    }

    section.watch {
        background: url(../img/watch_2560.png) center center no-repeat;
        padding-top: 233px;
        padding-bottom: 241px;

        &:before {
            top: 152px;
        }

        &:after {
            bottom: 156px;
        }
    }

    section.about {
        margin-top: 200px;

        &:before {
            margin-left: -1280px;
            border-right: 2560px solid transparent;
            border-bottom: 313px solid #ebebeb;
            top: -313px;
        }

        &:after {
            margin-left: -1280px;
            border-left: 2560px solid transparent;
            border-top: 313px solid #ebebeb;
            bottom: -313px;
        }

        h2 {
            margin-top: -120px;
        }
    }

    section.team {
        .wrapper:after {
            bottom: 22px;
        }
    }

    footer {
        margin-top: 65px;

        &:before {
            margin-left: -1280px;
            border-right: 2560px solid transparent;
            border-bottom: 313px solid #fff;
            top: -314px;
        }

        &:after {
            margin-left: -1280px;
            border-right: 2560px solid transparent;
            border-bottom: 313px solid #212121;
            top: -313px;
        }        
    }
}